const config = {
  ENVIRONMENT_NAME: 'testing',
  forwoodId: {
    URL: 'https://id.testing.newcrest.forwoodsafety.com',
    USER_TOKEN_URL: 'https://tt1obljbs0.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'ap-southeast-2',
    URL: 'https://54xy4wgx3f.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.10.0',
    HOSTNAME: 'https://admin.testing.newcrest.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.testing.newcrest.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.testing.newcrest.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.testing.newcrest.forwoodsafety.com',
    WEBSOCKET: 'wss://lssvqv4m17.execute-api.ap-southeast-2.amazonaws.com/testing'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;